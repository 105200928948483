<template>
  <div>
    <v-app-bar id="home-app-bar" app color="#FFF" elevation="1" height="80">
      <!-- <base-img
        :src="require('@/assets/merlinlogo.png')"
        class="mr-3 hidden-md-only"
        contain
        max-width="52"
        width="100%"
      /> -->

      <base-img
        :src="require('@/assets/merlinlogo.png')"
        contain
        max-width="128"
        width="100%"
      />

      <v-spacer />

      <div>
        <v-tabs class="hidden-sm-and-down" optional>
          <v-tab
            v-for="(name, i) in items"
            :key="i"
            :to="{ name }"
            :exact="name === 'Inicio'"
            :ripple="false"
            active-class="text--primary"
            class="font-weight-bold"
            min-width="96"
            text
          >
            {{ name }}
          </v-tab>
        </v-tabs>
      </div>

      <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer" />
    </v-app-bar>

    <home-drawer v-model="drawer" :items="items" />
  </div>
</template>

<script>
export default {
  name: "HomeAppBar",

  components: {
    HomeDrawer: () => import("./Drawer")
  },

  data: () => ({
    drawer: null,
    items: [
      "Inicio",
      "ventanas",
      "Cerrajería",
      "Fontanería",
      "Electricistas",
      "Persianas",
      "Albañilería",
      "electrodomesticos",
      "Contacto"
    ]
  })
};
</script>

<style lang="sass">
#home-app-bar
  .v-tabs-slider
    max-width: 24px
    margin: 0 auto

  .v-tab
    &::before
      display: none
</style>
